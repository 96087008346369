import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/bdTechies/prozuktischool.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Divider, SEO, Text } from '../views/components';
import AuthorList from '../views/pages/Authors/AuthorList';
import authors from '../../content/author.yaml';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    {
      /* import YAML from 'yamljs';
      const authors = YAML.parse(authorData); */
    }
    <SEO pageTitle="যোগাযোগ" pagePath="/contact/" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  যোগাযোগের ঠিকানা
      </Text>
      <Divider mdxType="Divider" />
      <p>{`শীঘ্রই জানানো হবে :)`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      